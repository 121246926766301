import React from 'react';
import { FaGithub } from 'react-icons/fa';

import './style.scss';

import gatsbyLogo from '../images/gatsby-icon.png';
import bulmaLogo from '../images/bulma-logo.png';
import Navbar from './navbar';

const Header = ({ siteTitle }) => (
	<section class="hero is-primary is-large">
		<Navbar />
		<div class="hero-body">
    <div class="container">
      <h1 class="title">
	  ODONTOIATRA.ONLINE
      </h1>
      <h2 class="subtitle">
	  Tutto quello che vuoi sapere sulla figura del dentista
      </h2>
    </div>
  </div>
</section>
);
  

export default Header;
