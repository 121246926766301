import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import './style.scss'

const Midsection3 = () => (
  <StaticQuery
    query={graphql`
      query SocialQuery2 {
        site {
          siteMetadata {
data
stima_casi_sommersi
stima_casi
casi_totali
nuovi_casi_perc
nuovi_casi_numero
positivi_attuali
positivi_attuali_perc
positivi_attuali_numero
morti
morti_perc
morti_numero
letalita
intensiva
intensiva_perc
intensiva_numero
intensiva_frazione
ospedalizzati
ospedalizzati_perc
ospedalizzati_numero
quarantena
quarantena_perc
quarantena_numero
guariti
guariti_perc
guariti_num
tamponi
tamponi_num
casi_testati
casi_testati_num
casi_testati_perc
lomb_casi
lomb_nc
lomb_m_num
lomb_m_tot
abr_casi
abr_nc
abr_m_num
abr_m_tot
bas_casi
bas_nc
bas_m_num
bas_m_tot
cam_casi
cam_nc
cam_m_num
cam_m_tot
mol_casi
mol_nc
mol_m_num
mol_m_tot
pug_casi
pug_nc
pug_m_num
pug_m_tot
liv_casi
liv_nc
          }
        }
      }
    `}
    render={data => (
      
<section class="section">
    <div class="container">
      <h1 class="title">TOTALE CASI: <b>{data.site.siteMetadata.casi_totali}</b></h1>
        <p className="subtitle is-size-5">
          <b>+{data.site.siteMetadata.nuovi_casi_numero}</b> (+{data.site.siteMetadata.nuovi_casi_perc})
        </p>
    </div>
    <div class="container">
      <h1 class="title">ATTUALMENTE POSITIVI: <b>{data.site.siteMetadata.positivi_attuali}</b></h1>
        <p className="subtitle is-size-5">
          <b> {data.site.siteMetadata.positivi_attuali_numero}</b> ({data.site.siteMetadata.positivi_attuali_perc})
        </p>
    </div>
    <div class="container">
      <h1 class="title">MORTI: <b>{data.site.siteMetadata.morti}</b></h1>
        <p className="subtitle is-size-5">
          <b>+{data.site.siteMetadata.morti_numero}</b> (+{data.site.siteMetadata.morti_perc})
        </p>
    </div>
    <hr class="navbar-divider"></hr>
        <p className="subtitle is-code">
        <code>dati aggiornati {data.site.siteMetadata.data}</code>
        </p>
    <hr class="navbar-divider"></hr>
    <div class="container">
      <h1 class="title">Numero tamponi: <b>{data.site.siteMetadata.tamponi}</b></h1>
        <p className="subtitle is-size-5">
          <b>+{data.site.siteMetadata.tamponi_num}</b> rispetto a ieri
        </p>
    </div>
    <div class="container">
      <h1 class="title">Numero casi testati: <b>{data.site.siteMetadata.casi_testati}</b></h1>
        <p className="subtitle is-size-5">
          <b>+{data.site.siteMetadata.casi_testati_num}</b> rispetto a ieri
        </p>
    </div>
    <div class="container">
      <h1 class="title">Percentuale popolazione testata: <b>{data.site.siteMetadata.casi_testati_perc}</b></h1>
    </div>
    <hr class="navbar-divider"></hr>
    <div class="container">
      <h1 class="title">Terapia intensiva: <b>{data.site.siteMetadata.intensiva}</b></h1>
        <p className="subtitle is-size-5">
          <b> {data.site.siteMetadata.intensiva_numero}</b> ({data.site.siteMetadata.intensiva_perc})
        </p>
    </div>
    <div class="container">
      <h1 class="title">Ospedalizzati: <b>{data.site.siteMetadata.ospedalizzati}</b></h1>
        <p className="subtitle is-size-5">
         <b>{data.site.siteMetadata.ospedalizzati_numero}</b> ({data.site.siteMetadata.ospedalizzati_perc})
        </p>
    </div>
    <div class="container">
      <h1 class="title">Quarantena: <b>{data.site.siteMetadata.quarantena}</b></h1>
        <p className="subtitle is-size-5">
          <b>{data.site.siteMetadata.quarantena_numero}</b> ({data.site.siteMetadata.quarantena_perc})
        </p>
    </div>
    <div class="container">
      <h1 class="title">Guariti: <b>{data.site.siteMetadata.guariti}</b></h1>
        <p className="subtitle is-size-5">
          <b>{data.site.siteMetadata.guariti_num}</b> ({data.site.siteMetadata.guariti_perc})
        </p>
    </div>

    <hr class="navbar-divider"></hr>
    <div class="container">
      <h1 class="title">Tasso di letalità apparente: <b>{data.site.siteMetadata.letalita}</b></h1>
        <p className="subtitle is-size-5">
        (morti/casi totali)
        </p>
    </div>
    <div class="container">
      <h1 class="title">Frazione ospedalizzati in terapia intensiva: <b>{data.site.siteMetadata.intensiva_frazione}</b></h1>
        <p className="subtitle is-size-5">
        (pz in terapia intensiva/ospedalizzati totali)
        </p>
    </div>
    <hr class="navbar-divider"></hr>
    <p className="subtitle is-code">
        <code>I numeri riportati di seguito sono prettamente teorici e "viziati" - soprattutto - da un conteggio dei deceduti che non rispecchia la realtà delle cose.</code>
        </p>
    <hr class="navbar-divider"></hr>
    <div class="container">
      <h1 class="title">Numero di casi totali stimati: <b>{data.site.siteMetadata.stima_casi}</b></h1>
        <p className="subtitle is-size-5">
        (sulla base dei valori di casi accertati, tasso di letalità apparente e tasso di letalità reale <u>stimato</u> dall'ISPI)
        </p>
    </div>
    <div class="container">
      <h1 class="title">Stima casi sommersi: <b>{data.site.siteMetadata.stima_casi_sommersi}</b></h1>
        <p className="subtitle is-size-5">
        (casi totali stimati - casi accertati)
        </p>
    </div>
    <hr class="navbar-divider"></hr>
    <div class="container">
      <h1 class="title">Lombardia</h1>
        <p className="subtitle is-size-5">
          Casi totali: <b>{data.site.siteMetadata.lomb_casi}</b>
        </p>
        <p className="subtitle is-size-5">
          Nuovi casi: <b>{data.site.siteMetadata.lomb_nc}</b>
        </p>
        <p className="subtitle is-size-5">
          Morti: <b>{data.site.siteMetadata.lomb_m_tot}</b> (+{data.site.siteMetadata.lomb_m_num})
        </p>
    </div>
    <hr class="navbar-divider"></hr>
    <div class="container">
      <h1 class="title">Abruzzo</h1>
        <p className="subtitle is-size-5">
          Casi totali: <b>{data.site.siteMetadata.abr_casi}</b>
        </p>
        <p className="subtitle is-size-5">
          Nuovi casi: <b>{data.site.siteMetadata.abr_nc}</b>
        </p>
        <p className="subtitle is-size-5">
          Morti: <b>{data.site.siteMetadata.abr_m_tot}</b> (+{data.site.siteMetadata.abr_m_num})
        </p>
    </div>
    <hr class="navbar-divider"></hr>
    <div class="container">
      <h1 class="title">Basilicata</h1>
        <p className="subtitle is-size-5">
          Casi totali: <b>{data.site.siteMetadata.bas_casi}</b>
        </p>
        <p className="subtitle is-size-5">
          Nuovi casi: <b>{data.site.siteMetadata.bas_nc}</b>
        </p>
        <p className="subtitle is-size-5">
          Morti: <b>{data.site.siteMetadata.bas_m_tot}</b> (+{data.site.siteMetadata.bas_m_num})
        </p>
    </div>
    <hr class="navbar-divider"></hr>
    <div class="container">
      <h1 class="title">Campania</h1>
        <p className="subtitle is-size-5">
          Casi totali: <b>{data.site.siteMetadata.cam_casi}</b>
        </p>
        <p className="subtitle is-size-5">
          Nuovi casi: <b>{data.site.siteMetadata.cam_nc}</b>
        </p>
        <p className="subtitle is-size-5">
          Morti: <b>{data.site.siteMetadata.cam_m_tot}</b> (+{data.site.siteMetadata.cam_m_num})
        </p>
    </div>
    <hr class="navbar-divider"></hr>
    <div class="container">
      <h1 class="title">Molise</h1>
        <p className="subtitle is-size-5">
          Casi totali: <b>{data.site.siteMetadata.mol_casi}</b>
        </p>
        <p className="subtitle is-size-5">
          Nuovi casi: <b>{data.site.siteMetadata.mol_nc}</b>
        </p>
        <p className="subtitle is-size-5">
          Morti: <b>{data.site.siteMetadata.mol_m_tot}</b> (+{data.site.siteMetadata.mol_m_num})
        </p>
    </div>
    <hr class="navbar-divider"></hr>
    <div class="container">
      <h1 class="title">Puglia</h1>
        <p className="subtitle is-size-5">
          Casi totali: <b>{data.site.siteMetadata.pug_casi}</b>
        </p>
        <p className="subtitle is-size-5">
          Nuovi casi: <b>{data.site.siteMetadata.pug_nc}</b>
        </p>
        <p className="subtitle is-size-5">
          Morti: <b>{data.site.siteMetadata.pug_m_tot}</b> (+{data.site.siteMetadata.pug_m_num})
        </p>
    </div>
    <hr class="navbar-divider"></hr>
    <div class="container">
      <h1 class="title">Livorno e provincia</h1>
        <p className="subtitle is-size-5">
          Casi totali: <b>{data.site.siteMetadata.liv_casi}</b>
        </p>
        <p className="subtitle is-size-5">
          Nuovi casi: <b>{data.site.siteMetadata.liv_nc}</b>
        </p>
    </div>
  </section>

    )}
  />
)

export default Midsection3