import React from 'react';

import './style.scss';
import Helmet from './helmet';
import Header from './header';
import Midsection from './midsection';
import Midsection2 from './midsection2';
import Midsection3 from './midsection3';
import Footer from './footer';

const Layout = ({ children }) => (
	<div>
		<Helmet />
		<Header />
		<Midsection />
		<Midsection2 />
		<Midsection3 />
		<Footer />
	</div>
);

export default Layout;
