import React from 'react'
import { FaTwitter, FaGithub, FaMedium } from 'react-icons/fa'
import { StaticQuery, graphql } from 'gatsby'
import './style.scss'
import Emoji from './emoji'

const Midsection2 = ({ siteTitle }) => (
	<div class="container is-fluid">
        <div class="tile is-ancestor">
  <div class="tile is-vertical is-8">
    <div class="tile">
      <div class="tile is-parent is-vertical">
        <article class="tile is-child notification is-primary">
          <p class="title">La visita odontoiatrica si paga?</p>
          <div class="content">
        La visita odontoiatrica è a tutti gli effetti una visita specialistica e, in quanto tale, ha un costo. L'odontoiatra, come qualsiasi altro specialista, può decidere non far pagare la visita solo in occasioni straordinarie e mai con l'intento di accaparrarsi i pazienti a scapito di altri colleghi. 
        </div>
        </article>
        <article class="tile is-child notification is-warning">
          <p class="title">Mi si è rotto un dente, cosa devo fare?</p>
          <p class="subtitle">Bottom tile</p>
        </article>
      </div>
      <div class="tile is-parent">
        <article class="tile is-child notification is-info">
          <p class="title">Perché è così importante lavare i denti?</p>
          <p class="subtitle">With an image</p>
          
        </article>
      </div>
    </div>
    <div class="tile is-parent">
      <article class="tile is-child notification is-danger">
        <p class="title">Perché il dentista costa tanto?</p>
        <p class="subtitle">La qualità ha un costo</p>
        <div class="content">
        L'odontoiatra è una figura altamente specializzata che lavora utilizzando strumenti, apparecchiature e materiali i quali hanno un costo non indifferente. Il dentista paga odontotecnici e fornitori, oltre a sostenere tutte le spese per i dipendenti. L'odontoiatra paga di tasca sua i costi dei corsi di aggiornamento obbligatori, paga l'affitto (o il mutuo) e le bollette. Ultimo ma non meno importante: l'odontoiatra, come tutti i liberi professionisti, paga le tasse e i contributi previdenziali obbligatori (ENPAM).
        </div>
      </article>
    </div>
  </div>
  <div class="tile is-parent">
    <article class="tile is-child notification is-success">
      <div class="content">
        <p class="title">Qual è il percorso per diventare odontoiatri?</p>
        <p class="subtitle">Laurea ma non solo</p>
        <div class="content">
          Per poter diventare dentisti bisogna ovviamente conseguire il diploma ed iscriversi al Corso di Laurea in Odontoiatria e Protesi Dentaria. L'accesso al corso di laurea è subordinato al superamento di uno specifico test di ammissione e ogni anno possono immatricolarsi meno di 1000 persone a livello nazionale. Il corso di laurea si articola in sei anni, comprende lo studio delle materie di base e di quelle caratterizzanti, così come include un monte ore di tirocinio professionalizzante da svolgere obbligatoriamente. Una volta conseguita la laurea, il neolaureato deve sostenere l'Esame di Stato per l'abilitazione alla professione di odontoiatra e solo dopo potrà iscriversi all'Ordine dei Medici Chirurghi e degli Odontoiatri, requisito fondamentale per iniziare a lavorare.
        </div>
      </div>
    </article>
  </div>
</div>
</div>

);

export default Midsection2;
