import React from 'react'
import { FaTwitter, FaGithub, FaFacebook, FaInstagram } from 'react-icons/fa'
import { StaticQuery, graphql } from 'gatsby'
import './style.scss'
import Emoji from './emoji'

const Footer = () => (
  <StaticQuery
    query={graphql`
      query SocialQuery {
        site {
          siteMetadata {
            gatsby
            bulma
            twitter
            medium
            github
            instagram
            facebook
          }
        }
      }
    `}
    render={data => (
      <footer className='footer center has-background-light'>
        <div className='content has-text-centered'>
          <p className='is-size-4'>
            Questo sito è stato realizzato sorseggiando un paio di{' '}
            <Emoji emoji='☕' />
          </p>
          <p className='is-size-4'>
            da Romano utilizzando{' '}
            <a href={data.site.siteMetadata.gatsby}>Gatsby</a> +{' '}
            <a href={data.site.siteMetadata.bulma}>Bulma</a>
          </p>
          <article className='media center'>
            <span className='icon'>
              <a href={data.site.siteMetadata.twitter}>
                <FaTwitter size='fa-2x' color='cyan' />
              </a>
            </span>
            &nbsp;
            <span className='icon'>
              <a href={data.site.siteMetadata.instagram}>
                <FaInstagram size='fa-2x' color='black' />
              </a>
            </span>
            &nbsp;
            <span className='icon'>
              <a href={data.site.siteMetadata.facebook}>
                <FaFacebook size='fa-2x' color='blue' />
              </a>
            </span>
            &nbsp;
          </article>
          &nbsp;
          <p className='is-size-5'>
            Puoi supportare questo progetto {' '}
            <a href='https://www.paypal.me/romanoiam'>offrendomi un caffè</a>
          </p>
        </div>
      </footer>
    )}
  />
)

export default Footer
