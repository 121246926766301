import React from 'react';
import {
	FaSass,
	FaHtml5,
	FaReact,
	FaMobileAlt,
	FaRocket,
	FaOsi
} from 'react-icons/fa';

import './style.scss';
import Emoji from './emoji';
import DenteNormale from '../images/dente-normale.png';
import DenteRotto from '../images/dente-rotto.png';
import Fresa from '../images/fresa.png';
import Orto from '../images/orto.png';
import Spazzolino from '../images/spazzolino.png';
import Specchietto from '../images/specchietto.png';

const Midsection = () => (
	<div>
		<section className="section">
			<div className="container">
				<div className="columns is-multiline">
					<div className="column is-one-third">
						<article className="media">
							<figure className="media-left">
								<span className="icon is-medium">
								<img src={DenteNormale} alt="Dente" />
								</span>
							</figure>
							<div className="media-content">
								<div className="content">
									<h1 className="title is-size-4">Chi è l'odontoiatra?</h1>
									<p className="subtitle is-size-5">
										L'odontoiatra, o dentista, è la figura professionale che si occupa della salute della bocca a 360 gradi. Possiede una laurea specialistica/magistrale ed è iscritto all'Ordine dei Medici e degli Odontoiatri.
									</p>
								</div>
							</div>
						</article>
					</div>
					<div className="column is-one-third">
						<article className="media">
							<figure className="media-left">
								<span className="icon is-medium">
								<img src={Fresa} alt="Dente" />
								</span>
							</figure>
							<div className="media-content">
								<div className="content">
									<h1 className="title is-size-4">C'è differenza con l'odontotecnico?</h1>
									<p className="subtitle is-size-5">
										L'odontotecnico è una figura diversa da quella dell'odontoiatra. Egli collabora con l'odontoiatra e si occupa della realizzazione dei manufatti che dovranno poi essere inseriti nel cavo orale dall'odontoiatra. L'odontotecnico non lavora sul paziente.
									</p>
								</div>
							</div>
						</article>
					</div>
					<div className="column is-one-third">
						<article className="media">
							<figure className="media-left">
								<span className="icon is-medium">
								<img src={Specchietto} alt="Dente" />
								</span>
							</figure>
							<div className="media-content">
								<div className="content">
									<h1 className="title is-size-4">Un medico può fare il dentista?</h1>
									<p className="subtitle is-size-5">
										Sì, ma solo se laureato in Medicina e Chirurgia, immatricolato al corso di laurea dopo il 28/01/1980 e in possesso del diploma di specializzazione in Odontostomatologia; oppure abilitati all'esercizio dell'odontoiatria secondo quanto previsto dal d.lgs 13 ottobre 1998, n. 386. Possono esercitare anche i Laureati in Medicina e Chirurgia, immatricolati al corso di laurea prima del 28/01/1980, con o senza specializzazione in Odontostomatologia.
									</p>
								</div>
							</div>
						</article>
					</div>
					<div className="column is-one-third">
						<article className="media">
							<figure className="media-left">
								<span className="icon is-medium">
								<img src={DenteRotto} alt="Dente" />
								</span>
							</figure>
							<div className="media-content">
								<div className="content">
									<h1 className="title is-size-4">L'odontoiatra può specializzarsi?</h1>
									<p className="subtitle is-size-5">
										Dopo aver conseguito la laurea, di per sé già altamente professionalizzante, l'odontoiatra può iscriversi ad una scuola di specializzazione al fine di rendere ancor più completo il suo bagaglio. Allo stato attuale delle cose, in Italia, esistono tre diverse specializzazioni: Ortognatodonzia, Chirurgia Odontostomatologica e Odontoiatria Pediatrica.
									</p>
								</div>
							</div>
						</article>
					</div>

					<div className="column is-one-third">
						<article className="media">
							<figure className="media-left">
								<span className="icon is-medium">
								<img src={Orto} alt="Dente" />
								</span>
							</figure>
							<div className="media-content">
								<div className="content">
									<h1 className="title is-size-4">Cosa fa l'ortodontista?</h1>
									<p className="subtitle is-size-5">
										L'ortodontista (o ortodonzista) è l'odontoiatra che ha conseguito il titolo di specializzazione in Ortognatodonzia. Si occupa dei problemi funzionali ed estetici derivanti dal malposizionamento delle arcate dentarie e dei denti in esse compresi. Con le sue conoscenze può purare anche i disordini a carico dell'articolazione temporo-mandibolare e dei muscoli ad essa associati.
									</p>
								</div>
							</div>
						</article>
					</div>
					<div className="column is-one-third">
						<article className="media">
							<figure className="media-left">
								<span className="icon is-medium">
								<img src={Spazzolino} alt="Dente" />
								</span>
							</figure>
							<div className="media-content">
								<div className="content">
									<h1 className="title is-size-4">Chi è il pedodonzista?</h1>
									<p className="subtitle is-size-5">
										Il pedonontista è l'odontoiatra che ha conseguito il titolo di specializzazione in Odontoiatria Pediatrica. È colui che si preoccupa di trattare tutte le patologie del cavo orale dei pazienti di età pediatrica.
									</p>
								</div>
							</div>
						</article>
					</div>
				</div>
			</div>
		</section>
	</div>
);

export default Midsection;
